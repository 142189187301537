<template>
  <v-container fluid>
    <head-title
      text="Facturas"
      :to="{ name: 'accounting-register-buy-home' }"
    ></head-title>
    <v-row>
      <v-col cols="12" md="6">
        <company-field
          v-model="companyId"
          @value="(val) => (companyId = val)"
        ></company-field>
      </v-col>
      <v-col cols="12" md="6">
        <provider-field v-model="providerId"></provider-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="2">
        <v-select
          :items="[
            { text: 'Pagadas', value: 'paid' },
            { text: 'Pendientes de pago', value: 'pending' },
            { text: 'Todas', value: 'all' },
          ]"
          label="Estado"
          v-model="status"
        ></v-select>
      </v-col>
      <v-col cols="12" md="3">
        <v-select
          :items="[
            { text: 'Por Mes', value: 0 },
            { text: 'Por Fecha', value: 1 },
            { text: 'Por Período', value: 2 },
            { text: 'Período Contable', value: 3 },
          ]"
          label="Mostrar Datos"
          v-model="searchType"
        ></v-select>
      </v-col>
      <v-col cols="12" md="4" v-if="searchType === 1">
        <date-field label="Fecha" v-model="date"></date-field>
      </v-col>
      <v-col cols="12" md="4" v-else-if="searchType === 0">
        <date-field label="Mes" v-model="date" type="month"></date-field>
      </v-col>
      <v-row v-else-if="searchType === 2">
        <v-col cols="12" md="4">
          <date-field label="De" v-model="date"></date-field>
        </v-col>
        <v-col cols="12" md="4">
          <date-field label="Hasta" v-model="until"></date-field>
        </v-col>
      </v-row>
      <v-row v-else-if="searchType === 3">
        <v-col>
          <v-select label="Mes" :items="uniqueMonth" v-model="until" ref="ref_period">
          </v-select>
        </v-col>
        <v-col>
          <v-select label="Año" :items="uniqueYear" v-model="date" ref="ref_period">
          </v-select>
        </v-col>
      </v-row>
      <v-col cols="12" md="1" class="text-left">
        <v-btn class="mx-1" fab dark small color="blue darken-3" @click="search()">
          <v-icon dark>mdi-database-search</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12" md="1" class="text-left">
        <v-btn fab dark small color="blue darken-3" @click="newInvoice()">
          <v-icon color="white">mdi-plus</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12" md="1" class="text-left">
        <v-btn fab dark small color="blue darken-3" @click="exportXlsxREport()">
          <v-icon color="white">mdi-file</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :search="filter"
      hide-default-footer
      disable-pagination
      class="elevation-1"
    >
      <template v-slot:top v-if="items.length > 0">
        <v-text-field
          v-model="filter"
          append-icon="mdi-magnify"
          label="Buscar"
          clearable
          class="mx-4"
        ></v-text-field>
      </template>
      <template v-slot:[`item.invoice_number`]="{ item }">
        <v-btn text color="primary" @click="getDetail(item.id)">
          {{ item.serie }} - {{ item.invoice_number }}
        </v-btn>
      </template>
      <template v-slot:[`item.amount`]="{ item }">
        <span v-if="item.amount != null">{{ item.amount | currency }}</span>
      </template>
      <template v-slot:[`item.is_credit`]="{ item }">
        <span v-if="item.is_credit">Crédito</span>
        <span v-else>Contado</span>
      </template>
      <template v-slot:[`item.deb`]="{ item }">
        <span v-if="item.deb != null">{{ item.deb | currency }}</span>
      </template>
      <template v-slot:[`item.tipo_dte`]="{ item }">
        <span v-if="item.document_type == null">FACTURA</span>
        <span v-else>{{ item.document_type.name }}</span>
      </template>
      <template v-slot:[`item.balance`]="{ item }">
        <span v-if="item.balance != null">{{ item.balance | currency }}</span>
      </template>
      <template v-slot:[`item.tax`]="{ item }">
        <span v-if="item.tax != null">{{ item.tax | currency }}</span>
      </template>
      <template v-slot:[`item.payable`]="{ item }">
        <span v-if="!item.payable && item.paid">Pagada</span>
        <span v-else>Pendiente de Pago</span>
      </template>
    </v-data-table>
    <detail-invoice
      v-if="items != undefined"
      :dialog="dialogDetail"
      :invoiceID="dtID"
      @close="(dialogDetail = false), (dtID = undefined), search()"
    >
    </detail-invoice>
    <new-invoice
      :dialog="invoiceDialog"
      :credit="'INVOICE'"
      :disabledP="false"
      @close="invoiceDialog = false"
      @saved="search()"
    ></new-invoice>
  </v-container>
</template>
<script>
import requests from "@/services/requests";
import xlsx from "@/services/sheetJs";

export default {
  components: {
    DetailInvoice: () => import("@/components/accounting/invoice/DetailInvoice"),
    HeadTitle: () => import("@/components/base/HeaderTitle"),
    CompanyField: () => import("@/components/fields/CompanyField"),
    DateField: () => import("@/components/fields/DateField.vue"),
    ProviderField: () => import("@/components/fields/ProviderField"),
    newInvoice: () => import("@/components/accounting/invoice/CreateInvoice"),
  },
  data: () => ({
    status: "all",
    columns: [
      [
        "Fecha",
        "Tipo documento",
        "Serie",
        "Número",
        "Proveedor",
        "NIT",
        "clasificación",
        "Periodo Contable",
        "Tipo",
        "Total",
        "IVA",
        "ISR",
        "Tipo exento",
        "Exento",
        "Sucursal",
      ],
    ],
    periods: [],
    uniqueMonth: [],
    uniqueYear: [],
    dialogDetail: false,
    dtID: undefined,
    filter: "",
    providerId: undefined,
    companyId: 0,
    date: "",
    until: "",
    year: null,
    month: null,
    searchType: null,
    items: [],
    loading: false,
    invoiceDialog: false,
    headers: [
      { text: "Factura", value: "invoice_number" },
      { text: "Fecha", value: "invoice_date" },
      { text: "Descripción", value: "description" },
      { text: "Proveedor", value: "provider.name" },
      { text: "Tipo", value: "tipo_dte" },
      { text: "Total", value: "amount" },
      { text: "Saldo", value: "balance" },
      { text: "Creado por", value: "created_by.username" },
      { text: "Estado", value: "payable", align: "end" },
    ],
  }),
  mounted() {
    this.searchType = 0;
  },
  methods: {
    validateIsNull(value, period = false) {
      if (value == null) return "";
      else if (period) {
        return value.month + "-" + value.year;
      } else {
        return value.name;
      }
    },
    exportXlsxREport() {
      if (this.items.length > 0) {
        var data = this.items.map((row) => ({
          invoice_date: row.invoice_date,
          document_type: row.document_type.name,
          serie: row.serie,
          number: row.invoice_number,
          provider: row.provider.name,
          nit: row.provider.nit,
          classification: this.validateIsNull(row.classification),
          working_period: this.validateIsNull(row.working_period, true),
          type: row.type,
          total: row.amount,
          iva: row.iva,
          isr: row.isr,
          type_exempt: this.validateIsNull(row.type_exempt),
          exempt: row.exempt,
          sucursal: row.company.second_name,
        }));
        xlsx.exportXlsx(data, this.columns, "Documentos", "Documentos Tributarios.xlsx");
      } else {
        this.$toasted.global.info({
          message: "No hay datos",
        });
      }
    },
    newInvoice() {
      this.invoiceDialog = true;
    },
    getList(uri) {
      this.loading = true;
      requests.get(uri).then((response) => {
        if (response.status == 200) {
          this.items = response.data;
        }
        this.loading = false;
      });
    },
    search() {
      var uri = "";
      if (this.date === null) {
        this.$toasted.global.info({
          message: "Seleccione la fecha",
        });
        return;
      }

      if ((this.companyId == undefined) | (this.companyId <= 0)) {
        this.$toasted.global.info({
          message: "Seleccione una Sucursal",
        });
        return;
      }
      if (this.searchType === 1) {
        uri = "api/invoice/?date=" + this.date;
      } else if (this.searchType === 0) {
        uri =
          "api/invoice/?year=" +
          this.date.substring(0, 4) +
          "&month=" +
          this.date.substring(5, 7);
      } else if (this.searchType === 2 || this.searchType === 3) {
        if (this.until === null) {
          this.$toasted.global.info({
            message: "Seleccione la fecha",
          });
          return;
        } else {
          uri = "api/invoice/?date=" + this.date + "&until=" + this.until;
          if (this.searchType === 3) {
            uri += "&period=true";
          }
        }
      }
      if (this.providerId != undefined) {
        uri += "&provider_id=" + this.providerId.id;
      }
      if (this.status != undefined) {
        uri += "&status=" + this.status;
      }
      uri += "&company_id=" + this.companyId;
      this.getList(uri);
    },
    getDetail(id) {
      this.dialogDetail = true;
      this.dtID = id;
    },
    getWorkingPeriod() {
      requests.get("api/accounting/working-period/").then((res) => {
        if (res.status == 200) {
          this.periods = res.data.results;
          // this.getMonths(this.periods);
        } else {
          console.error(res);
        }
      });
    },
    getReport() {},
  },
  watch: {
    companyId(val) {
      if (typeof val === "object") {
        this.companyId = this.companyId.id;
      }
    },
    searchType(newValue) {
      if (newValue === 3) {
        this.getWorkingPeriod();
      }
      if (newValue === 1) {
        this.date = new Date().toISOString().substring(0, 10);
      }
      if (newValue === 0) {
        this.date = new Date().toISOString().substring(0, 7);
      }
      if (newValue === 2) {
        this.date = new Date().toISOString().substring(0, 10);
        this.until = new Date().toISOString().substring(0, 10);
      }
    },
    periods(val) {
      var months = [];
      var years = [];
      this.uniqueYear = [];
      this.uniqueMonth = [];
      val.forEach((element) => {
        months.push(element.month);
        years.push(element.year);
      });
      months.forEach((el) => {
        if (this.uniqueMonth.indexOf(el) === -1) {
          this.uniqueMonth.push(el);
        }
      });
      years.forEach((el) => {
        if (this.uniqueYear.indexOf(el) === -1) {
          this.uniqueYear.push(el);
        }
      });

      this.uniqueMonth.sort(function (a, b) {
        return b - a;
      });

      if (this.uniqueYear.length == 1) {
        this.date = this.uniqueYear[0];
      }
    },
  },
};
</script>
