import XLSX from 'xlsx';

// function validateIsNull(value, period = false) {
//     if (value == null)
//         return ''
//     else
//         if (period) {
//             return value.month + "-" + value.year
//         } else {
//             return value.name
//         }
// }

class GenerateXlsx {

    exportXlsx(rows, columns,workbook_name, file_name) {
        // const raw_data = data

        // const rows = raw_data.map(row => ({
        //     invoice_date: row.invoice_date,
        //     document_type: row.document_type.name,
        //     serie: row.serie,
        //     number: row.invoice_number,
        //     provider: row.provider.name,
        //     nit: row.provider.nit,
        //     classification: validateIsNull(row.classification),
        //     working_period: validateIsNull(row.working_period, true),
        //     type: row.type,
        //     total: row.amount,
        //     iva: row.iva,
        //     isr: row.isr,
        //     type_exempt: validateIsNull(row.type_exempt),
        //     exempt: row.exempt,
        //     sucursal: row.company.second_name
        // }));

        // GENERATE WORKSHEET AND WORKBOOK
        const workseet = XLSX.utils.json_to_sheet(rows)
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, workseet, workbook_name);

        // FIX HEADERS
        XLSX.utils.sheet_add_aoa(workseet, columns, { origin: "A1" });

        // calculate column width
        // const max_width = rows.reduce((w,r)=> Math.max(w, r.))

        // CREATE AN XLSX FILE AND TRY TO SAVE
        XLSX.writeFile(workbook, file_name, { compression: true });

    }
}

export default new GenerateXlsx();